import { gql } from '@apollo/client';

export const INSTERT_ONE_COMPANY_TASK = gql`
	mutation insertOneCompanyTask(
		$title: String!
		$companyId: ID!
		$type: CompanyTaskType!
		$dueDate: DateTime!
		$description: String
	) {
		companyTask: insertOneCompanyTask(
			title: $title
			companyId: $companyId
			type: $type
			dueDate: $dueDate
			description: $description
		) {
			_id
		}
	}
`;
