import { useState } from 'react';
import { Link } from 'react-router';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import ApartmentIcon from '@mui/icons-material/Apartment';

import CreateCompanyTaskDialog from 'web/screens/dialogs/companies/CreateCompanyTaskDialog';

export default function AppSpeedDialActions({ companyId = null }) {
	const [createDialogType, setCreateDialogType] = useState(null);

	return (
		<div>
			<SpeedDial
				ariaLabel="Hurtigvalg bedrift"
				sx={{ position: 'fixed', bottom: '5vw', right: '5vw' }}
				icon={<SpeedDialIcon />}
			>
				<SpeedDialAction
					icon={<AssignmentTurnedInIcon />}
					tooltipTitle="Legg til oppgave"
					onClick={() => setCreateDialogType('task')}
				/>

				<SpeedDialAction
					icon={<AssignmentLateIcon />}
					tooltipTitle="Legg til avvik"
					onClick={() => setCreateDialogType('deviation')}
				/>

				<SpeedDialAction
					icon={<ApartmentIcon />}
					tooltipTitle="Opprett ny bedrift"
					to="/bedrifter/ny"
					component={Link}
				/>
			</SpeedDial>

			<CreateCompanyTaskDialog
				open={!!createDialogType}
				companyId={companyId}
				type={createDialogType}
				onClose={() => setCreateDialogType(null)}
			/>
		</div>
	);
}
