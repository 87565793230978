import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import CommentIcon from '@mui/icons-material/Comment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import MuiFormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { formatDate } from 'utils/dates';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useUser } from 'web/contexts/UserContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import FileUploadDialog from 'web/screens/dialogs/files/FileUploadDialog';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import { StyledALink } from 'web/components/Link';

import Form, {
	FormGroup,
	Select,
	DatePicker,
	MarkdownField,
} from 'web/form/Form';

import { FIND_ONE_COMPANY_TASK } from 'api/queries/companyTaskQueries';

import { GET_FILES } from 'api/queries/fileQueries';
import { DELETE_ONE_FILE } from 'api/mutations/fileMutations';

import { PAGINATE_COMMENTS } from 'api/queries/commentQueries';
import {
	INSERT_ONE_COMMENT,
	DELETE_ONE_COMMENT,
} from 'api/mutations/commentMutations';

const typesLabels = {
	task: 'Oppgave',
	deviation: 'Avvik',
};

export default function CompanyTaskEditDialog({ taskId, isOpen, onClose }) {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			fullWidth={true}
			maxWidth="md"
			scroll="body"
			PaperProps={{
				sx: {
					minHeight: 400,
				},
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CompanyTaskEditDialogContent taskId={taskId} onClose={onClose} />
		</Dialog>
	);
}

function CompanyTaskEditDialogContent({ taskId, onClose }) {
	const [tabIndex, setTabIndex] = useState(0);

	const {
		data: { companyTask } = { companyTask: {} },
		loading: companyTaskLoading,
		error: companyTaskError,
	} = useQuery(FIND_ONE_COMPANY_TASK, { variables: { _id: taskId } });

	const {
		data: { files = [] } = { files: [] },
		loading: filesLloading,
		error: filesError,
	} = useQuery(GET_FILES, { variables: { docId: taskId } });

	const {
		data: { comments } = { comments: { count: 0, items: [] } },
		loading: commentsLoading,
		error: commentsError,
	} = useQuery(PAGINATE_COMMENTS, {
		variables: {
			docId: taskId,
			orderBy: 'createdAt',
			order: 1,
		},
	});

	if (companyTaskLoading || filesLloading || commentsLoading) {
		return <InlineLoading />;
	}

	return (
		<div>
			<CompanyTaskEditDialogTitle
				title={`${typesLabels[companyTask.type]}: ${companyTask.title}`}
				onClose={onClose}
			/>

			<DialogContent dividers>
				<ErrorMessage
					errors={[companyTaskError, filesError, commentsError]}
				/>

				<Box
					sx={{
						flexGrow: 1,
						display: 'flex',
					}}
				>
					<VerticalTabs
						value={tabIndex}
						onChange={newTabIndex => setTabIndex(newTabIndex)}
					>
						<VerticalTab label="Info" index={0} />

						<VerticalTab
							label="Vedlegg"
							count={files.length}
							index={1}
						/>

						<VerticalTab
							label="Kommentarer"
							count={comments.count}
							index={2}
						/>

						<VerticalTab label="Historikk" index={3} />
					</VerticalTabs>

					<TabPanel value={tabIndex} index={0}>
						<CompanyTaskStatus taskId={taskId} />

						<TaskDeadlineEntryDate
							taskId={taskId}
							date={companyTask.dueDate}
						/>

						<TaskDeadlineDescription
							taskId={taskId}
							description={companyTask.description}
						/>
					</TabPanel>

					<TabPanel value={tabIndex} index={1}>
						<CompanyTaskFiles taskId={taskId} files={files} />
					</TabPanel>

					<TabPanel value={tabIndex} index={2}>
						<CompanyTasksComments
							taskId={taskId}
							comments={comments.items}
						/>
					</TabPanel>

					<TabPanel value={tabIndex} index={3}>
						<CompanyTaskHistory
							history={[
								{
									_id: '1',
									event: 'Test hendelse',
									createdAt: new Date(),
									userId: '1',
									userDisplayName: 'Ola Nordmann',
								},
								{
									_id: '2',
									event: 'En annen hendelse',
									createdAt: new Date(),
									userId: '2',
									userDisplayName: 'Kari Nordmann',
								},
							]}
						/>
					</TabPanel>
				</Box>
			</DialogContent>
		</div>
	);
}

function CompanyTaskEditDialogTitle({ title, onClose }) {
	return (
		<>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>

			<DialogCloseButton onClick={onClose} />
		</>
	);
}

function VerticalTabs({ tabIndex, onChange, children, ...rest }) {
	return (
		<Tabs
			orientation="vertical"
			value={tabIndex}
			onChange={(_e, newTabIndex) => onChange(newTabIndex)}
			aria-label="Rediger oppgave faner"
			sx={{
				borderRight: 1,
				borderColor: 'divider',
				minWidth: 150,
				'&& .MuiTab-root': {
					alignItems: 'baseline',
				},
			}}
			{...rest}
		>
			{children}
		</Tabs>
	);
}

const VerticalTabLabel = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
	width: 100%;
`;

const VerticalTabLabelText = styled.div`
	line-height: 1;
`;

const VerticalTabLabelBadge = styled.div`
	background-color: ${({ theme }) => theme.palette.primary.main};
	color: ${({ theme }) => theme.palette.text.white};
	border-radius: 50%;
	display: inline-block;
	font-size: 12px;
	height: 20px;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
`;

function VerticalTab({ label, count = 0, index, ...rest }) {
	return (
		<Tab
			{...rest}
			label={
				<VerticalTabLabel>
					<VerticalTabLabelText>{label}</VerticalTabLabelText>

					{count > 0 && (
						<VerticalTabLabelBadge>{count}</VerticalTabLabelBadge>
					)}
				</VerticalTabLabel>
			}
			id={`vertical-tab-${index}`}
			aria-controls={`vertical-tabpanel-${index}`}
		/>
	);
}

const TabPanelWrapper = styled.div`
	padding: 10px 20px;
	width: 100%;
`;

const PanelSection = styled.div`
	margin-bottom: 30px;
`;

const PanelTitle = styled.h3`
	display: flex;
	align-items: center;
	gap: 5px;
	line-height: 1.6;
	margin: 0 0 20px 0;
`;

function TabPanel({ children, value, index, ...other }) {
	return (
		<TabPanelWrapper
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</TabPanelWrapper>
	);
}

function CompanyTaskStatus({ taskID }) {
	return (
		<PanelSection>
			<PanelTitle>Status</PanelTitle>

			<Stack direction="row" spacing={2}>
				<Button
					variant={'outlined'}
					onClick={() => console.log('click')}
					fullWidth
				>
					Ikke aktuelt
				</Button>

				<Button
					variant={'outlined'}
					onClick={() => console.log('click')}
					fullWidth
				>
					Utført
				</Button>

				<Button
					variant={'outlined'}
					onClick={() => console.log('click')}
					fullWidth
				>
					Kontrollert
				</Button>
			</Stack>
		</PanelSection>
	);
}

const DeadlineEntryDateDisplay = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

function TaskDeadlineEntryDate({ taskId, entryId, date }) {
	const { notify } = useSnackbar();
	const [editMode, setEditMode] = useState(false);

	// const [updateDeadlineEntry, { loading, error }] = useMutation(
	// 	UPDATE_ONE_TASK_DEADLINE_ENTRY,
	// 	{
	// 		refetchQueries: [
	// 			{
	// 				query: GET_TASK_DEADLINE_ENTRIES,
	// 				variables: {
	// 					taskId,
	// 				},
	// 			},
	// 		],
	// 	}
	// );

	const loading = false;
	const error = false;

	return (
		<PanelSection>
			<PanelTitle>Frist</PanelTitle>

			{editMode ? (
				<Form
					values={{
						newDate: date,
					}}
					onSubmit={async ({ newDate }) => {
						try {
							console.log(newDate);
							// const { data } = await updateDeadlineEntry({
							// 	variables: {
							// 		_id: entryId,
							// 		input: {
							// 			date: newDate,
							// 		},
							// 	},
							// });

							// if (data?.deadlineEntry?._id) {
							// 	notify(`Dato ble oppdatert`);
							// }

							setEditMode(false);
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<ErrorMessage errors={[error]} />

					<FormGroup>
						<DatePicker name="newDate" label="Ny dato" required />
					</FormGroup>

					<Button
						onClick={() => {
							setEditMode(false);
						}}
						size="small"
					>
						Avbryt
					</Button>

					<Button
						type="submit"
						variant="contained"
						size="small"
						disabled={loading}
					>
						Lagre
					</Button>
				</Form>
			) : (
				<DeadlineEntryDateDisplay>
					<div>{formatDate(date, 'DD.MM.YYYY')}</div>

					<IconButton
						title="Endre frist"
						size="small"
						onClick={() => setEditMode(true)}
					>
						<EditIcon fontSize="small" />
					</IconButton>
				</DeadlineEntryDateDisplay>
			)}
		</PanelSection>
	);
}

function TaskDeadlineDescription({ taskId, description }) {
	if (!description) {
		return null;
	}

	return (
		<PanelSection>
			<PanelTitle>Beskrivelse</PanelTitle>

			{description}
		</PanelSection>
	);
}

const EntryFilesHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
`;

const EntryFilesTitle = styled.h3`
	margin: 0;
`;

const NotFoundMessage = styled.div`
	font-style: italic;
	margin: 40px 0;
	text-align: center;
`;

function CompanyTaskFiles({ taskId, files }) {
	const { verify } = useConfirmDialog();
	const [showDialog, setShowDialog] = useState(false);

	const [removeFile, { loading: removeFileLoading, error: removeFileError }] =
		useMutation(DELETE_ONE_FILE, {
			refetchQueries: [
				{
					query: GET_FILES,
					variables: {
						docId: taskId,
					},
				},
			],
		});

	return (
		<div>
			<EntryFilesHeader>
				<EntryFilesTitle>Vedlegg</EntryFilesTitle>

				<Button
					size="small"
					variant="contained"
					color="primary"
					type="button"
					startIcon={<SaveAltIcon />}
					onClick={() => {
						setShowDialog(true);
					}}
				>
					Legg til dokument
				</Button>
			</EntryFilesHeader>

			<ErrorMessage errors={[removeFileError]} />

			{files?.length > 0 ? (
				<List dense={true}>
					{files.map(file => (
						<ListItem
							key={file._id}
							secondaryAction={
								<IconButton
									edge="end"
									aria-label="Slett dokument"
									title="Slett dokument"
									disabled={removeFileLoading}
									onClick={async () => {
										try {
											if (
												!(await verify({
													title: `Slett dokument`,
													text: `Er du sikker på at du vil slette ${file.fileName}?`,
												}))
											) {
												return;
											}

											await removeFile({
												variables: {
													_id: file._id,
												},
											});
										} catch (err) {
											console.error(err);
										}
									}}
								>
									<DeleteIcon />
								</IconButton>
							}
						>
							<ListItemAvatar>
								<Avatar>
									<ArticleIcon />
								</Avatar>
							</ListItemAvatar>

							<ListItemText
								primary={
									<StyledALink
										href={file.url}
										title={`Last ned ${file.fileName}`}
										target="_blank"
										rel="noreferrer"
									>
										{file.fileName}
									</StyledALink>
								}
								secondary={`Lastet opp av ${
									file.createdByDisplayName
								} ${formatDate(
									file.createdAt,
									'DD.MM.YYYY kl. HH:mm'
								)}`}
							/>
						</ListItem>
					))}
				</List>
			) : (
				<NotFoundMessage>
					Det er ikke lastet opp noen dokumenter
				</NotFoundMessage>
			)}

			<FileUploadDialog
				docId={taskId}
				uploadContext="companyTask"
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</div>
	);
}

const CommentFormActions = styled.div`
	margin-top: 15px;
	display: flex;
	justify-content: flex-end;
`;

function CompanyTasksComments({ taskId, comments }) {
	const { user } = useUser();
	const { verify } = useConfirmDialog();

	const [addComment, { loading: addCommentLoading, error: addCommentError }] =
		useMutation(INSERT_ONE_COMMENT, {
			refetchQueries: ['paginateComments'],
		});

	const [
		removeComment,
		{ loading: removeCommentLoading, error: removeCommentError },
	] = useMutation(DELETE_ONE_COMMENT, {
		refetchQueries: ['paginateComments'],
	});

	return (
		<PanelSection>
			<PanelTitle>Kommentarer</PanelTitle>

			<ErrorMessage errors={[addCommentError, removeCommentError]} />

			{comments?.length > 0 && (
				<List dense={true}>
					{comments.map(comment => (
						<ListItem
							key={comment._id}
							secondaryAction={
								comment.createdBy === user._id && (
									<IconButton
										edge="end"
										aria-label="Slett kommentar"
										title="Slett kommentar"
										disabled={removeCommentLoading}
										onClick={async () => {
											try {
												if (
													!(await verify({
														title: `Slett kommentar`,
														text: `Er du sikker på at du vil slette kommentaren?`,
													}))
												) {
													return;
												}

												await removeComment({
													variables: {
														_id: comment._id,
													},
												});
											} catch (err) {
												console.error(err);
											}
										}}
									>
										<DeleteIcon />
									</IconButton>
								)
							}
						>
							<ListItemAvatar>
								<Avatar>
									<CommentIcon />
								</Avatar>
							</ListItemAvatar>

							<ListItemText
								primary={comment.message}
								secondary={`Skrevet av ${
									comment.createdByDisplayName
								} ${formatDate(
									comment.createdAt,
									'DD.MM.YYYY kl. HH:mm'
								)}`}
							/>
						</ListItem>
					))}
				</List>
			)}

			<form
				onSubmit={async e => {
					try {
						e.preventDefault();
						const message = e.target.message.value.trim();

						const { data } = await addComment({
							variables: {
								docId: taskId,
								message,
							},
						});

						if (data?.comment?._id) {
							e.target.reset();
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<p>Legg igjen en kommentar</p>

				<TextField name="message" rows={4} multiline fullWidth />

				<CommentFormActions>
					<Button
						type="submit"
						variant="contained"
						size="small"
						disabled={addCommentLoading}
					>
						Legg inn
					</Button>
				</CommentFormActions>
			</form>
		</PanelSection>
	);
}

function CompanyTaskHistory({ history }) {
	return (
		<PanelSection>
			<PanelTitle>Historikk</PanelTitle>

			{history && history.length > 0 && (
				<div>
					{history.map(event => (
						<HistoryEvent key={event._id} event={event} />
					))}
				</div>
			)}
		</PanelSection>
	);
}

const HistoryEventWrapper = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.palette.common.gray};
	margin-bottom: 10px;
	padding-bottom: 10px;

	&:last-of-type {
		border-bottom: none;
	}
`;

const HistoryByline = styled.div`
	font-style: italic;
	font-size: 0.8em;
`;

function HistoryEvent({ event }) {
	return (
		<HistoryEventWrapper>
			<div>{event.event}</div>

			<HistoryByline>
				{formatDate(event.createdAt, 'DD.MM.YY kl. HH:mm')} av{' '}
				{event.userDisplayName}
			</HistoryByline>
		</HistoryEventWrapper>
	);
}
