import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';
import { UnderlineLink } from 'web/components/Link';

import Panel, { EmptyPanel } from 'web/components/panels/Panel';

import CalendarIcon from 'web/components/widgets/components/CalendarIcon';

import CompanyTaskEditDialog from 'web/components/panels/dialogs/CompanyTaskEditDialog';

import { PAGINATE_COMPANY_TASKS } from 'api/queries/companyTaskQueries';

const typesLabels = {
	task: 'Oppgaver',
	deviation: 'Avvik',
};

const emptyListMessages = {
	task: 'Bedriften har ingen oppgaver.',
	deviation: 'Bedriften har ingen avvik.',
};

export default function CompanyTasksPanel({ companyId, type }) {
	const {
		data: { companyTasks = { count: 0, items: [] } } = {
			companyTasks: { count: 0, items: [] },
		},
		loading,
		error,
	} = useQuery(PAGINATE_COMPANY_TASKS, {
		variables: {
			companyId,
			type,
			orderBy: 'dueDate',
			order: 1,
		},
	});

	return (
		<>
			<Panel title={`${typesLabels[type]}`}>
				<ErrorMessage errors={[error]} />

				{loading ? (
					<InlineLoading />
				) : (
					<CompanyTasksLists
						companyTasks={companyTasks.items}
						type={type}
					/>
				)}
			</Panel>
		</>
	);
}

function CompanyTasksLists({ companyTasks = [], type }) {
	if (!companyTasks || companyTasks.length === 0) {
		return <EmptyPanel>{emptyListMessages[type]}</EmptyPanel>;
	}

	return (
		<List>
			{companyTasks.map(task => (
				<CompanyTasksListItem key={task._id} task={task} />
			))}
		</List>
	);
}

const Task = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;

function CompanyTasksListItem({ task }) {
	const [isOpenDialog, setIsOpenDialog] = useState(false);

	return (
		<>
			<ListItem>
				<Task>
					<CalendarIcon date={task.dueDate} />

					<Button onClick={() => setIsOpenDialog(true)}>
						{task.title}
					</Button>
				</Task>
			</ListItem>

			<CompanyTaskEditDialog
				taskId={task._id}
				isOpen={isOpenDialog}
				onClose={() => setIsOpenDialog(false)}
			/>
		</>
	);
}
