import AppSpeedDialActions from 'web/components/AppSpeedDialActions';

import CompanyTasksPanel from 'web/components/panels/CompanyTasksPanel';

import { Grid, GridItem } from 'web/components/company/GridLayout';

export default function CompanyTasksTab({ companyId }) {
	return (
		<div>
			<Grid>
				<GridItem>
					<CompanyTasksPanel companyId={companyId} type="deviation" />
				</GridItem>

				<GridItem>
					<CompanyTasksPanel companyId={companyId} type="task" />
				</GridItem>
			</Grid>
		</div>
	);
}
