import { useQuery, useMutation } from '@apollo/client';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';

import Form, {
	FormGroup,
	TextField,
	DatePicker,
	AutoComplete,
} from 'web/form/Form';

import { PAGINATE_MY_TENANTS } from 'api/queries/tenantQueries';
import { INSTERT_ONE_COMPANY_TASK } from 'api/mutations/companyTaskMutations';

const typesLabels = {
	task: 'oppgave',
	deviation: 'avvik',
};

const typeOptions = [
	{
		value: 'task',
		label: 'Oppgave',
	},
	{
		value: 'deviation',
		label: 'Avvik',
	},
];

export default function CreateCompanyTaskDialog({
	open,
	onClose,
	companyId = null,
	type = null,
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Legg til {typesLabels[type]}
			</DialogTitle>

			<DialogCloseButton onClick={onClose} />

			<AddCompanyTaskForm
				companyId={companyId}
				type={type}
				onClose={onClose}
			/>
		</Dialog>
	);
}

function AddCompanyTaskForm({ companyId = null, type = null, onClose }) {
	const { notify } = useSnackbar();

	const {
		data: { companies = { count: 0, items: [] } } = {
			companies: { count: 0, items: [] },
		},
		loading: companiesLoading,
		error: companiesError,
	} = useQuery(PAGINATE_MY_TENANTS, {
		variables: {
			orderBy: 'name',
			order: 1,
		},
	});

	const [addTask, { loading: addTaskLoading, error: addTaskError }] =
		useMutation(INSTERT_ONE_COMPANY_TASK, {
			refetchQueries: ['paginateCompanyTasks'],
		});

	return (
		<Form
			values={{
				companyId,
				type,
			}}
			onSubmit={async values => {
				try {
					const { data } = await addTask({
						variables: {
							...values,
						},
					});

					if (data?.companyTask?._id) {
						notify(`${typesLabels[type]} opprettet!`);
						onClose();
					}
				} catch (err) {
					console.error(err);
				}
			}}
		>
			<DialogContent>
				<ErrorAlert error={companiesError} />
				<ErrorAlert error={addTaskError} />

				<FormGroup>
					<TextField name="title" label="Tittel" fullWidth required />
				</FormGroup>

				<FormGroup>
					<AutoComplete
						name="type"
						label="Type"
						options={typeOptions}
					/>
				</FormGroup>

				<FormGroup>
					<AutoComplete
						name="companyId"
						label="Bedrift"
						loading={companiesLoading}
						options={companies?.items?.map(company => ({
							value: company._id,
							label: company.name,
						}))}
					/>
				</FormGroup>

				<FormGroup>
					<DatePicker name="dueDate" label="Forfallsdato" />
				</FormGroup>

				<FormGroup>
					<TextField
						name="description"
						label="Beskrivelse"
						rows={4}
						multiline
						fullWidth
					/>
				</FormGroup>
			</DialogContent>

			<DialogActions>
				<Button onClick={close}>Avbryt</Button>

				<Button
					type="submit"
					variant="contained"
					disabled={addTaskLoading}
				>
					Opprett
				</Button>
			</DialogActions>
		</Form>
	);
}
