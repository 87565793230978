import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const controlTypes = [
	{
		value: 'arsregnskap-skattemelding',
		label: 'Årsregnskap og skattemelding',
	},
	{ value: 'kvalitet', label: 'Kvalitet' },
];

export default function CompanyControlTypesFilter({
	disabled = false,
	size = 'medium',
}) {
	const { controlType, dispatch } = useAppFilters();

	return (
		<Filter
			name="company-controls-filter"
			label="Vis bedriftskontroll"
			value={controlType}
			options={controlTypes}
			onChange={value =>
				dispatch({
					type: actionTypes.SET_CONTROL_TYPE,
					payload: value,
				})
			}
			disabled={disabled}
			size={size}
			minWidth="300px"
			disableClearable={true}
		/>
	);
}
