import { useNavigate } from 'react-router';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

export default function CreateFabButton({
	label = 'Legg til',
	to = null,
	onClick = null,
	...props
}) {
	const navigate = useNavigate();

	return (
		<Tooltip title={label} placement="top">
			<Fab
				aria-label={label}
				color="primary"
				onClick={() => {
					if (to) {
						navigate(to);
					}
					if (onClick) {
						onClick();
					}
				}}
				sx={{ position: 'fixed', bottom: '5vw', right: '5vw' }}
				{...props}
			>
				<AddIcon />
			</Fab>
		</Tooltip>
	);
}
