import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';

import useSortOrder from 'hooks/useSortOrder';

import NotFound from 'web/screens/NotFound';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import DesktopLayout, { Content } from 'web/components/Layout';
import CompanyInfo from 'web/components/CompanyInfo';
import Link from 'web/components/Link';
import FileBrowser from 'web/components/views/FileBrowser';
import AppSpeedDialActions from 'web/components/AppSpeedDialActions';

import { GET_COMPANY_DOCUMENTS } from 'api/queries/documentQueries';
import { GET_ONE_COMPANY } from 'api/queries/tenantQueries';

export default function CompaniesSingle({ documentNodeId }) {
	let { companyId } = useParams();

	const [sort, setNewOrder] = useSortOrder('order');
	const parentId = documentNodeId || 'root';
	const tenantId = companyId;

	const {
		data: { company } = { company: {} },
		loading: companyLoading,
		error: companyError,
	} = useQuery(GET_ONE_COMPANY, {
		variables: { _id: companyId },
		errorPolicy: 'all',
	});

	const {
		data: { documentNode, documentNodes = { items: [], count: 0 } } = {},
		loading: documentNodesLoading,
		error: documentNodesError,
	} = useQuery(GET_COMPANY_DOCUMENTS, {
		variables: {
			tenantId,
			parentId,
			order: sort.order,
			orderBy: sort.orderBy,
		},
		errorPolicy: 'all',
	});

	if (!company) {
		return (
			<NotFound
				label="Bedrift ikke funnet"
				title="Denne bedriften ble ikke funnet!"
			/>
		);
	}

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					label: (company && company.name) || '...',
				},
			]}
			title={company && company.name}
		>
			<Content>
				{(companyLoading || documentNodesLoading) && <InlineLoading />}

				{!companyLoading && !documentNodesLoading && (
					<>
						<CompanyInfo company={company} />

						<ErrorMessage
							errors={[companyError, documentNodesError]}
						/>

						<h2>
							{(documentNode && documentNode.name) ||
								'Dokumenter'}
							{documentNode && (
								<Link
									to={
										'../' +
										((documentNode.parentId !== 'root' &&
											documentNode.parentId + '/') ||
											'')
									}
								>
									{'< Tilbake'}
								</Link>
							)}
						</h2>

						{documentNodes && documentNodes.items && (
							<FileBrowser
								documents={documentNodes}
								parentId={documentNodeId}
								parentName={documentNode?.name}
								tenantId={tenantId}
								linkBase={`/bedrifter/${
									(company && company._id) || ''
								}/`}
								sort={sort}
								setNewOrder={setNewOrder}
							/>
						)}
					</>
				)}
			</Content>

			<AppSpeedDialActions companyId={company._id} />
		</DesktopLayout>
	);
}
