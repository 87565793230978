import { gql } from '@apollo/client';

export const PAGINATE_COMPANY_TASKS = gql`
	query paginateCompanyTasks(
		$companyId: ID!
		$type: CompanyTaskType
		$order: Int
		$orderBy: String
		$offset: Int
		$limit: Int
	) {
		companyTasks: paginateCompanyTasks(
			companyId: $companyId
			type: $type
			order: $order
			orderBy: $orderBy
			skip: $offset
			limit: $limit
		) {
			count
			items {
				_id
				title
				dueDate
				description
			}
		}
	}
`;

export const FIND_ONE_COMPANY_TASK = gql`
	query findOneCompanyTask($_id: ID!) {
		companyTask: findOneCompanyTask(_id: $_id) {
			_id
			title
			type
			dueDate
			description
		}
	}
`;
